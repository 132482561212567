import { Fragment, useState } from 'react';
import { NavLink } from 'react-router-dom'
import clbadge from './../assets/clbadge.png';
import { nav_text } from '../text/text-generic-src';

import { NUNO_EMAIL } from '../Constants';


const Navigation = ({ languageChangeHandler, lang}) => {
  return (
    <Fragment>
      <MobileNavigation languageChangeHandler={languageChangeHandler} lang={lang}/>
      <DesktopNavigation languageChangeHandler={languageChangeHandler} lang={lang}/>
    </Fragment>
  )
}
const DesktopNavigation = ({languageChangeHandler, lang}) => {

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const closeDropdown = () => { setDropdownOpen(!dropdownOpen) }
  

  
  // Dropdown menu and link styles
  var style_contactsDropdown = "w-full absolute top-[0px] left-0 bg-accent-secondary gap-2 p-3 flex flex-row px-8 justify-center text-white lowercase transition-all h-[52px] items-center shadow-custom2";
  var style_contactsDropdownLink = 'cursor-pointer text-sm text-white opacity-[0.9] hover:opacity-100 group transition-all';
  
  // Navigation bar link styles
  let style_navlink = "text-xl lg:text-[17px] hover:text-accent text-primary transition-all duration-300";
  let style_navlinkActive = "underline underline-offset-2 text-xl lg:text-[17px] text-accent transition-all duration-300";

  let style_navLinkButton = "text-sm text-secondary hover:text-accent transition-all duration-300 bg-white border border-2 hover:border-accent lg:pl-2 lg:pr-1  rounded-md flex justify-center items-center uppercase font-light select-none ";

  let style_navLinkButtonOpen = "text-sm text-accent-tertiary hover:text-white transition-all duration-300 bg-accent-secondary border lg:pl-2 lg:pr-1 border border-2 border-accent rounded-md flex justify-center items-center uppercase font-light select-none ";
  
  let style_lang = "text-primary text-[17px] relative cursor-pointer";
  let style_langSelected = "text-[17px] text-accent relative select-none underline underline-offset-2 cursor-pointer";


  return(
    
    // BEGIN Desktop Navigation
    <div className = 'hidden lg:block sticky top-0 left-0 w-full z-20' >

      {/* BEGIN Contacts Dropdown */}
      <div className = {dropdownOpen ? style_contactsDropdown+" translate-y-[90px]" : style_contactsDropdown}>
        <a className={style_contactsDropdownLink} href={`mailto:${NUNO_EMAIL}`}>
          <span className='group-hover:underline' >

            {NUNO_EMAIL}
          </span>
        </a>
        <span className="text-sm text-accent-tertiary select-none">-</span>
        <a className={style_contactsDropdownLink} href="https://scholar.google.com/citations?user=6Ivzwp8AAAAJ&hl=pt-PT">
          <span className='group-hover:underline'>Google Scholar</span><sup><i className="ri-external-link-fill"></i></sup>
        </a>
        <a className={style_contactsDropdownLink} href="https://www.linkedin.com/in/nunoagmendes/">
          <span className='group-hover:underline'>Linkedin</span><sup><i className="ri-external-link-fill"></i></sup>
        </a>
      </div>
      {/* END Contacts Dropdown */}

      {/* BEGIN Desktop Navigation Menu */ }
      <nav className='bg-white sticky w-full top-0 h-[90px] hidden lg:flex lg:items-end lg:justify-start lg:px-[10%] shadow-custom1 z-30 border border-t-0  border-x-0 border-b-gray-100 py-3 '>
        
        {/*BEGIN Logo*/}
        <NavLink reloadDocument to="/" className='text-4xl xl:text-5xl flex items-end test_font mr-6 font-semibold text-primary select-none'>
          N.&nbsp;Mendes
        </NavLink>
        {/*END Logo*/}
        
        {/*BEGIN Navigation Bar */}
        <div className="flex flex-row justify-between w-full">
          
          {/* BEGIN Navigation Links */}
          <ul className="uppercase select-none flex gap-x-3 items-end w-full justify-start">

            <li>
              <NavLink to="/" className={({ isActive }) => isActive ? style_navlinkActive : style_navlink}>
                {nav_text[lang].homepage}
              </NavLink>
            </li>

            <li><NavLink to="/cv" className={({ isActive }) => isActive ? style_navlinkActive : style_navlink}>
                {nav_text[lang].cv}
              </NavLink>
            </li>

            <li><NavLink to="/research" className={({ isActive }) => isActive ? style_navlinkActive : style_navlink}>
              {nav_text[lang].research}
            </NavLink>
            </li>

            <li>
              <NavLink to="/publications" className={({ isActive }) => isActive ? style_navlinkActive : style_navlink}>
                {nav_text[lang].publications}
              </NavLink>
            </li>
          </ul>
          {/* END Navigation Links */}


          <span className={dropdownOpen ? style_navLinkButtonOpen + " cursor-pointer mr-4" : style_navLinkButton + " cursor-pointer mr-4"} onClick={closeDropdown}>
            {nav_text[lang].contacts}
            {dropdownOpen ?
              (<i className="text-xl ri-arrow-up-s-line"></i>)
              :
              (<i className="text-xl ri-arrow-down-s-line"></i>)
            }
          </span>

          {/* BEGIN Language Handler Buttons */}
          <div className='flex flex-row justify-end uppercase items-center'>
            <div onClick={() => languageChangeHandler("pt")} className={lang == "pt" ? style_langSelected : style_lang}>
              PT
            </div>
            <span className='select-none mx-1 text-[11px] text-white lg:text-primary'>|</span>
            <div onClick={() => languageChangeHandler("en")} className={lang == "en" ? style_langSelected : style_lang}>
              EN
            </div>
          </div>
          {/* END Language Handler Buttons */}

        </div>
        {/*END Navigation Bar*/}

      </nav>
      {/* END Desktop Navigation Menu */}

    </div >
    // END Desktop Navigation
  )
}
const MobileNavigation = ({languageChangeHandler, lang}) => {
  
  const [menuOpen, setMenuOpen] = useState(false);
  const closeMenu = () => { setMenuOpen(!menuOpen) }

  // Mobile side menu and button styles
  var style_mobileMenu = "bg-primary lg:hidden fixed w-[75%] md:w-[50%] top-[63px] h-[calc(100%-63px)] shadow-custom1 lg:hidden transition-all duration-300 z-20";
  var style_mobileMenuButton = "bg-primary w-16 h-16 relative top-0 flex justify-center items-center cursor-pointer transition-all text-white z-20 duration-700 rounded-bl-2xl ";
  var style_mobileMenuButtonArrow = "text-[32px] text-white"
  const menuOpenClass = " -right-[75%] md:-right-[50%]";
  const menuClosedClass = " right-0";
  const arrowLeftClass = "  text-[32px] ri-menu-line";
  const arrowRightClass = " text-[32px]  ri-close-fill";

  // Mobile menu link list styles
  var style_langMobile = "text-white text-[17px] relative cursor-pointer";
  var style_langSelectedMobile = "text-[17px] text-accent relative select-none underline underline-offset-2"
  var style_navlinkMobile = "text-xl lg:text-[17px] text-white hover:text-accent transition-all duration-300";
  var style_navlinkActiveMobile = "text-xl lg:text-[17px] text-accent transition-all duration-300"
  var style_mobileMenuLinksList = "flex flex-col items-end gap-4"
  if (!menuOpen) {
    style_mobileMenuButton += 'right-[64px]';
    style_mobileMenu += menuOpenClass;
    style_mobileMenuButtonArrow += arrowLeftClass;
  } else {
    style_mobileMenuButton += 'right-[0px]';
    style_mobileMenu += menuClosedClass;
    style_mobileMenuButtonArrow += arrowRightClass;
  }

  return(

    // BEGIN Mobile Navigation
    <>

      {/* BEGIN Static Header */}
      <div className='lg:hidden bg-darkest w-full h-16 sticky top-[0px] flex justify-start items-center cursor-pointer transition-all  text-white shadow-custom1 z-30'>
        <NavLink reloadDocument to="/" className=" text-3xl text-white flex ml-4 justify-end font-semibold select-none">
          N.&nbsp;Mendes
        </NavLink>
      </div>
      {/* END Static Header */}

      {/* BEGIN Mobile Navigation Menu */}
      <nav className={style_mobileMenu}>

        {/* BEGIN MNM Button */}
        <div className={style_mobileMenuButton} onClick={closeMenu}>
          <i className={style_mobileMenuButtonArrow} />
        </div>
        {/* END MNM Button */}

        {/* BEGIN MNM Links */}
        <div className=" w-full px-8 md:px-12 flex flex-col gap-4 landscape:gap-2 h-[95%] landscape:h-[85%] absolute top-0 py-[18px]">
      
          
            <ul className={style_mobileMenuLinksList}>

              <li>
                <NavLink to="/" onClick={closeMenu}
                  className={({ isActive }) => isActive ? style_navlinkActiveMobile : style_navlinkMobile}>
                  {nav_text[lang].homepage}
                </NavLink>
              </li>

              <li>
                <NavLink to="/cv" onClick={closeMenu}
                  className={({ isActive }) => isActive ? style_navlinkActiveMobile : style_navlinkMobile}>
                  {nav_text[lang].cv}
                </NavLink>
              </li>

            <li>
              <NavLink to="/research" onClick={closeMenu}
                className={({ isActive }) => isActive ? style_navlinkActiveMobile : style_navlinkMobile}>
                {nav_text[lang].research}
              </NavLink>
            </li>

              <li>
                <NavLink to="/publications" onClick={closeMenu}
                  className={({ isActive }) => isActive ? style_navlinkActiveMobile : style_navlinkMobile}>
                  {nav_text[lang].publications}
                </NavLink>
              </li>
            </ul>

          <hr className=' h-[2px] w-full bg-white hidden landscape:block my-4 md:my-8 landscape:my-0' />
            <ul className="flex flex-col items-end justify-end gap-4 landscape:flex-row landscape:justify-between">
              
              <li>
                <span className="text-4xl text-white flex flex-row gap-3">
                  <i className="ri-mail-fill hover:text-accent transition-all "></i>
                  <i className="ri-graduation-cap-fill hover:text-accent transition-all "></i>
                  <i className="ri-linkedin-box-fill hover:text-accent transition-all "></i>
                </span>
              </li>

            <hr className=' h-[2px] w-full bg-white landscape:hidden my-4 md:my-8'/>

              <div className='flex flex-row justify-end uppercase items-center'>
                <div onClick={() => languageChangeHandler("pt")} className={lang == "pt" ? style_langSelectedMobile : style_langMobile}>
                  PT
                </div>
                <span className='select-none mx-1 text-[11px] text-white lg:text-primary'>|</span>
                <div onClick={() => languageChangeHandler("en")} className={lang == "en" ? style_langSelectedMobile : style_langMobile}>
                  EN
                </div>
              </div>
            </ul>
        </div>
        {/* END MNM Links */}
        
      </nav>
      {/* END Mobile Navigation Menu */}

    </>
    // END Mobile Navigation
  )
}



const Footer = ({lang}) =>{

  return(
    <footer className='relative'>

      {/* Lower Footer */}
      <div className="bg-[#1c2122] absolute bottom-0 left-0 w-full z-10 py-2 text-sm font-light">
        <div className="container mx-auto px-4">
          <div className="-mx-4 flex flex-wrap gap-7 justify-center text-secondary font-light">
            <div className="">
              NAGM © 2024
            </div>
            <div className="">
              web dev. j.md
            </div>
          </div>
        </div>
      </div>
    </footer>

  )
}

export { Navigation, Footer }