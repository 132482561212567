
// New notation
// Language is defined as a sub-prop of each prop instead of being separate objects piled into one.

/* Variable structure

Object = 
{
  cv-timeline-items = 
  [
    {
      title
      duration
      year
      description
      links
    },
    cv-item
    cv-item
    (...)
  ]


}
*/


import splash_ren from "./../assets/splash_ren.webp"
import splash_graduation from "./../assets/splash_graduation.webp"
import splash_deec from "./../assets/splash_deec.webp"
import splash_iscac from "./../assets/splash_iscac.webp"
import splash_jest from "./../assets/splash_jest.webp"
import splash_pes from "./../assets/splash_pes.webp"
import splash_neec from "./../assets/splash_neec.webp"


var icons = {
  university: <i className="ri-graduation-cap-fill"></i>
  ,
  award: <i className="ri-award-fill"></i>
  ,
  research: <i className="ri-search-line"></i>
  ,
  job: <i className="ri-briefcase-line"></i>,
}




// Has image, links
var licenciatura = {
  size: "lg",
  title: {
    en: "BSc in Electrical and Computer Engineering @ UC",
    pt: "BSc em Eng. Eletrotécnica e Computadores @ UC"
  },
  duration: {
    en: "3 years",
    pt: "3 anos"
  },
  interval: { start: "2017", end: "2020" },
  year: "2017",
  inner: icons.university,
  image: splash_deec,
  desc: {
    en: "Concluded with a gpa of 13, with participation in several extracurricular projects",
    pt: "Concluída com média final 13 e com participação em vários projetos extracurriculares"
  },
  links: [
    { title: "Course Description @ UC", link:"https://apps.uc.pt/courses/PT/course/8922"},
  ],
  category: ["education"]
}
// Has image, links
var mestrado = {
  size: "lg",
  title: {
    en: "MSc in Electrical and Computer Engineering @ UC",
    pt: "MSc em Eng. Eletrotécnica e Computadores @ UC"
  },
  duration: {
    en: "2 years",
    pt: "2 anos"
  },
  interval: { start: "2020", end:"2022"},
  year: "2020",
  inner: icons.university,
  desc: {
    en: "Master's Degree concluded with the thesis \"Federated Learning for the Prediction of Net Energy Demand in Communities of Buildings\"",
    pt: "Concluído o mestrado com a tese \"Federated Learning for the Prediction of Net Energy Demand in Communities of Buildings\""
  },
  image: splash_graduation,
  links: [
    { title: "Course Description", link:"https://apps.uc.pt/courses/PT/course/9241"},
    { title: "Estudo Geral", link:"https://estudogeral.uc.pt/handle/10316/102905"},
    { title: "IEEE Xplore", link:"https://ieeexplore.ieee.org/document/9960350/"}
  ],
  category: ["education"]
}

var doutoramento ={
  size: "lg",
  title: {
    en: "PhD in Electrical and Computer Engineering @UC",
    pt: "PhD em Eng. Eletrotécnica e Computadores @UC"
  },
  duration: {
    en: "ongoing",
    pt: "atual"
  },
  interval: {start:"2022"},
  year: "2022",
  inner: icons.university,
  desc: {
    en: "Specializing in energy with a sub-spec. in energy systems and smart-grid management",
    pt: "Especializado em energia com sub-especialização em sistemas de energia e gestão de redes inteligentes"
  },
  links: [
    { title: "Course Description @ UC", link:"https://apps.uc.pt/courses/en/course/323"},
  ],
  category: ["education", "highlight"]
}
// Has image, links
var iscac = {
  size: "lg",
  title: {
    en: "Postgrad Management Degree @ ISCAC",
    pt: "Pós-graduação em Gestão de Empresas @ ISCAC"
  },
  duration: {
    en: "1 year",
    pt: "1 ano"
  },
  interval: { start: "2023", end: "2024"},
  year: "2023",
  inner: icons.university,
  image: splash_iscac,
  desc: {
    en: "Attending enterprise management course to understand business fundamentals.",
    pt: "A frequentar um curso de gestão de empresas para ganhar bases de gestão de negócios."
  },
  links: [
    { title: "ISCAC", link:"https://www.iscac.pt/"},
    { title: "Gestão PMEs @ ISCAC", link:"https://bs.iscac.pt/posgraduacao/GestaoPMEs16"}
  ],
  category: ["education", "highlight"]
}

var jest = {
  size: "md",
  title: {
    en: "Junior Enterprise (JEST)",
    pt: "Júnior Empresa (JEST)"
  },
  duration: {
    en: "6 months",
    pt: "6 meses"
  },
  desc: {
    en: "Collaborating in the tech. and innovation department at JEST",
    pt: "Colaborador no departamento de tecnologia e inovação da JEST"
  },
  image: splash_jest,
  links: [
    { title: "JEST Website", link:"https://jest.pt/"}
  ],
  category: ["extracurricular"]
}
// Has links
var pes = {
  size: "sm",
  title: {
    en: "President of PES-IEE UC Branch",
    pt: "Presidente do ramo da UC do PES-IEEE"
  },
  duration: {
    en: "1 year",
    pt: "1 ano"
  },
  interval: { start: "2023", end: "2024" },
  year: "2023",
  links: [
  ],
  desc: {
    en: "Coordinating and managing the PES branch, with workshops, activities and presentations of various energy management topics. Hosted the first edition of the Energy Forum event.",
    pt: "A coordenar e dinamizar o ramo do PES, com workshops, atividades e apresentação de tópicos relacionados com a gestão energética. Organizador da primeira edição do Energy Forum."
  },
  image:splash_pes,
  category: ["extracurricular"],
  links : [
    { title: "IEEE SB UC", link:"https://www.facebook.com/ieeeucsb/"},
    { title: "PES UC Instagram", link:"https://www.instagram.com/ieee_pes_uc/"},
    { title: "PES UC Linktree", link:"https://linktr.ee/ieee_pes_uc"}
  ]
}
var sb = {
  size: "sm",
  title: {
    en: "Chair of IEEE SB UC Branch",
    pt: "Presidente IEEE SB UC"
  },
  duration: {
    en: "ongoing",
    pt: "atual"
  },
  interval: { start: "2024",},
  year: "2024",
  links: [
  ],
  desc: {
    en: "Coordinating and managing the Student Branch, preparing and organizing workshops and activities to improve the connection between students and the IEEE research community",
    pt: "A coordenar e dinamizar o Student Branch, preparando e organizando workshops e atividades para melhorar a ligação entre os estudantes e a comunidade de investigação IEEE"
  },
  category: ["extracurricular"],
  links: [
    { title: "IEEE SB UC", link: "https://www.facebook.com/ieeeucsb/" },
  ]
}

var neec = {
  size: "sm",
  title: {
    en: "Supporting pedagocic department at NEEEC",
    pt: "Apoio ao gabinete de pedagogia do NEEEC"
  },
  duration: {
    en: "1 year",
    pt: "1 ano"
  },
  desc:{
    en: "In conjunction with other tasks associated with the support of fellow students",
    pt: "Em conjunto com outras atividades de apoio à comunidade estudantil"
  },
  links: [
    { title: "NEEEC facebook", link:"https://www.facebook.com/aac.neeec/"},
    { title: "NEEEC/AAC", link:"https://academica.pt/orgaos/nucleos/neeec-aac/"}
  ],
  image: splash_neec,
  category: ["extracurricular"]
}
// Has image, links
var ren = {
  size: "xl",
  title: {
    en: "REN Master's Thesis Award",
    pt: "Prémio REN de Teses de Mestrado"
  },
  year: "2023",
  desc: {
    en: "Awarded 1st place in the 2023 edition of the REN Awards for Energy and Energy System management master's theses",
    pt: "Premiado 1º lugar na edição de 2023 dos prémios REN para teses de mestrados na área da energia e gestão de sistemas de energia"
  },
  inner: icons.award,
  links: [
    { title: "REN Website", link:"https://www.ren.pt/pt-pt/sustentabilidade/iniciativas/premio-ren"},
    { title: "Noticias UC", link: "https://noticias.uc.pt/artigos/tese-de-mestrado-da-universidade-de-coimbra-conquista-primeiro-lugar-do-premio-ren-2023/" },
    { title: "Diário de Coimbra", link: "https://www.diariocoimbra.pt/noticia/114147" },
  ],
  image: splash_ren,
  auto_open:true,
  category: ["highlight"]
}






export var cv_contents = {
  cv_timeline_items:
  [
      sb,
      ren,
      iscac,
      pes,
      doutoramento,
      mestrado,
      neec,
      jest,
      licenciatura,
  ]
}
