
import face from './../assets/headshot.webp';

import { about_text_en, about_text_pt } from '../text/text-generic-src';

export const About = ({lang}) =>{

  const Job = (props) =>{

    // If props.jobsite is a list
    if (Array.isArray(props.jobsite)){
      return(
        <h2 className='text-xs md:text-sm font-primary text-secondary mb-1'>
          {props.title}
          &nbsp;@
          {
            props.jobsite.map((each, i) =>
              <>
                <a className='underline underline-offset-[4px] hover:text-accent transition-all duration-300'
                  href={props.href[i]} key={i}>
                  {each}
                </a>
                {(i != props.jobsite.length - 1) ? "-" : ""}
              </>
            )
          }
        </h2>)
    }
    else{
      return(
        <h2 className='text-xs md:text-sm font-primary text-secondary mb-1'>
          {props.title}
          &nbsp;@
          <a className='underline underline-offset-[4px] hover:text-accent transition-all duration-300'
            href={props.href}>
            {props.jobsite}
          </a>
        </h2>
      )
    }
  }
  const IconicLink = (props) =>{
     return(
       <a className="text-accent-secondary hover:text-accent  transition-all flex items-center gap-3 group cursor-pointer" href={props.link}>
        {props.icon}
         <p className='hidden w-[50%] lg:flex justify-between text-sm text-secondary select-none transition-all group-hover:ml-2'>
          
           <span className='relative after:bg-secondary group-hover:after:bg-accent after:absolute after:h-[1px] after:w-0 after:-bottom-[-4px] after:left-0 group-hover:after:w-full after:transition-all after:duration-500 cursor-pointer group-hover:text-accent'>
            {props.text}
          </span>

           <i className='ri-arrow-right-line opacity-0 group-hover:opacity-75 text-lg text-green-600 transition-all'>
           </i>
        </p>
      </a>
     )
  }
  return(
    <section className=' py-8  mb-0 xl:pb-6 lg:pt-12 flex flex-grow bg-gradient-to-b from-white to-grey'>

      <div className='container mx-auto flex flex-col lg:flex-row p-2 md:p-6 pt-0 lg:pt-4 self-center lg:self-start'>



          <div className="p-6 w-full lg:w-[400px] h-full flex flex-col items-center lg:items-start justify-center lg:justify-start py-0">

            <div className='rounded-lg w-[200px] md:w-[250px] lg:h-[200px] xl:h-[250px] xl:w-full object-cover overflow-hidden flex'>
            <img className=" shadow-custom2 self-center justify-self-center" src={face} />
            </div>
           
            <h1 className='text-2xl md:text-3xl font-semibold font-headings mt-6 mb-2 '>Nuno Mendes</h1>
            {
            (lang == "en") ?
              about_text_en.current.map((each, i) =>
                <div key={i}>
                  <p className='text-primary leading-snug text-sm hidden md:block'>
                    {each.title.long}
                  </p>
                  <p className='text-primary leading-snug text-sm md:hidden'>
                    {each.title.short}
                  </p>
                </div>
              )
              :
              about_text_pt.current.map((each, i) =>
                <div key={i}>
                  <p className='text-primary leading-snug text-sm hidden lg:block'>
                    {each.title.long}
                  </p>
                  <p className='text-primary leading-snug text-sm lg:hidden'>
                    {each.title.short}
                </p>
                </div>

              )
            }
            
   



            <hr className='hr my-3 lg:my-3 w-[75%] lg:w-full bg-primary'></hr>
            <div className='flex flex-col items-center lg:items-start'>
              {
                (lang == "en") ?
                  about_text_en.jobs.map((each, i) =>
                    <Job title={each.title} key={i} href={each.href} jobsite={each.jobsite} />
                  )
                  :
                  about_text_pt.jobs.map((each, i) =>
                    <Job title={each.title} key={i} href={each.href} jobsite={each.jobsite} />
                  )
              }
            </div>

            <div className='-dark m-0 my-4 text-[32px] w-full flex justify-center flex-row lg:flex-col gap-3 xl:gap-1 '>
            <IconicLink icon={<i className="ri-mail-fill"></i>} text="e-mail" link="mailto:nuno.mendes@ieee.org.pt"/>
              <IconicLink icon={<i className="ri-graduation-cap-fill"></i>} text="google scholar" link="https://scholar.google.com/citations?user=6Ivzwp8AAAAJ&hl=pt-PT"/>
              <IconicLink icon={<i className="ri-linkedin-box-fill"></i>} text="linkedIn" link="https://www.linkedin.com/in/nunoagmendes/"/>
            </div>

          </div>

        

          <div className="w-full lg:w-full h-full p-6 py-0 hidden lg:flex flex-col">
          <h2 className="text-3xl font-headings mb-4">
            {(lang == "en") ? about_text_en.about_me.heading : about_text_pt.about_me.heading}
          </h2>
          <div className='text-base leading-loose mb-6'>
            {
              (lang == "en") ?
                about_text_en.about_me.content.map((each,i) => <p key={i}>{each}</p>)
                :
                about_text_pt.about_me.content.map((each, i) => <p key={i}>{each}</p>)
            }
          </div>
          <hr className='my-4'></hr>
          <h2 className="text-3xl font-headings mb-4">
            {(lang == "en") ? about_text_en.current_projects.heading : about_text_pt.current_projects.heading}
          </h2>
          <div className='text-base leading-loose '>
            {
              (lang == "en") ?
                about_text_en.current_projects.content.map((each,i) => <p key={i}>{each}</p>)
                :
                about_text_pt.current_projects.content.map((each,i) => <p key={i}>{each}</p>)
            }
          </div>
          </div>


      </div>
    </section>
  )
}